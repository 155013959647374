import {useEffect, useState} from "react";
// for work_images
// works small images
// blog post images
// blog image small

//  icon use as img here
import icon from "../assets/images/icons/icon-1.svg";
import icon1 from "../assets/images/icons/icon-2.svg";
import icon5 from "../assets/images/icons/icon-6.svg";
// contact image
import iconPhone from "../assets/images/contact/phone-call 1.png";
import iconEmail from "../assets/images/contact/email 1.png";
import {CgNotes} from "react-icons/cg";
import {FaBlogger, FaRegUser} from "react-icons/fa";
import {AiOutlineHome} from "react-icons/ai";
import {FiCodesandbox} from "react-icons/fi";
import {RiContactsBookLine} from "react-icons/ri";
import {Link, useLocation} from "react-router-dom";

const AllData = () => {
  const [check, setCheck] = useState(false);
  const [local, setLocal] = useState(localStorage.getItem("theme"));
  const [singleData, setSingleData] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  // dark and light theme controls
  useEffect(() => {
    const themeValue = localStorage?.getItem("theme");

    if (!themeValue) {
      setCheck(false);
      localStorage.setItem("theme", "light");
    } else {
      themeValue === "dark" && setCheck(true);
      themeValue === "light" && setCheck(false);
    }

    localStorage?.getItem("theme") === "dark"
      ? document.documentElement.classList.add("dark")
      : document.documentElement.classList.remove("dark");
  }, []);

  // Create and light theme function
  const handleTheme = (value) => {
    if (value === "light") {
      setCheck(false);
      localStorage.setItem("theme", "light");
      setLocal("light");
    } else {
      setCheck(true);
      localStorage.setItem("theme", "dark");
      setLocal("dark");
    }
    localStorage?.getItem("theme") === "dark"
      ? document.documentElement.classList.add("dark")
      : document.documentElement.classList.remove("dark");
  };

  // fillter portfilo data
  const handleData = (text) => {
    if (text === "All") {
      setData(workItems);
    } else {
      const findData = workItems.filter((item) => item.tag === text);
      setData(findData);
    }
  };

  // find items for portfilo  modal open
  const handleModelData = (id) => {
    const find = workItems.find((item) => item?.id === id);
    setSingleData(find);
    setIsOpen(true);
  };

  // find items for Blogs  modal open
  const handleBlogsData = (id) => {
    const find = blogsData.find((item) => item?.id === id);
    setSingleData(find);
    setIsOpen(true);
  };

  // Active navlinks function
  function NavLink({
    to,
    className,
    activeClassName,
    inactiveClassName,
    ...rest
  }) {
    let location = useLocation();
    let isActive = location.pathname === to;
    let allClassNames =
      className + (isActive ? `${activeClassName}` : `${inactiveClassName}`);
    return <Link className={allClassNames} to={to} {...rest} />;
  }

  // Elements for protfilo section
  const workItems = [
    // {
    //   id: "1",
    //   tag: "UI/UX",
    //   title: "Chul urina",
    //   img: work1,
    //   imgSmall: workSmall1,
    //   bg: "#FFF0F0",
    //   client: "Envato",
    //   langages: "Photoshop, Figma",
    //   link: "https://www.envato.com",
    //   linkText: "www.envato.com",
    //   description:
    //     "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Mollitia placeat magnam possimus iusto blanditiis pariatur labore explicabo quo repellat hic dolorum numquam asperiores, voluptatum fugiat reiciendis aspernatur, non, odio aperiam voluptas ex tempora vitae. Dolor, consequatur quidem! Quas magni distinctio dolorum dolore natus, vel numquam accusamus. Nostrum eligendi recusandae qui tempore deserunt!",
    // },
    // {
    //   id: "2",
    //   tag: "Web Design",
    //   title: "Aura Dione",
    //   img: work2,
    //   imgSmall: workSmall2,
    //   bg: "#FFF3FC",
    //   client: "Themeforest",
    //   langages: "HTML, CSS, Javascript",
    //   link: "https://www.themeforest.net",
    //   linkText: "themeforest.net",
    //   description:
    //     "  Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cupiditate non suscipit voluptatibus minima ullam maiores sequi nihil placeat error, vero eaque doloremque reiciendis amet pariatur consequuntur. Nostrum, dolore, amet eligendi ipsam enim quisquam, corrupti asperiores nihil excepturi aspernatur placeat iure.",
    // },
    // {
    //   id: "3",
    //   tag: "Logo",
    //   title: "Chul urina",
    //   img: work3,
    //   imgSmall: workSmall3,
    //   bg: "#FFF0F0",
    //   client: "Freepik",
    //   langages: "Illustrator",
    //   link: "https://www.freepik.com/free-photos-vectors/market-logo",
    //   linkText: "www.freepik.com",
    //   description:
    //     "  Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cupiditate non suscipit voluptatibus minima ullam maiores sequi nihil placeat error, vero eaque doloremque reiciendis amet pariatur consequuntur. Nostrum, dolore, amet eligendi ipsam enim quisquam, corrupti asperiores nihil excepturi aspernatur placeat iure.",
    // },
    // {
    //   id: "4",
    //   tag: "Video",
    //   title: "Chul urina",
    //   img: work4,
    //   imgSmall: workSmall4,
    //   bg: "#E9FAFF",
    //   client: "Envato",
    //   langages: "After Effect",
    //   link: "https://www.envato.com",
    //   linkText: "www.envato.com",
    //   description:
    //     "  Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cupiditate non suscipit voluptatibus minima ullam maiores sequi nihil placeat error, vero eaque doloremque reiciendis amet pariatur consequuntur. Nostrum, dolore, amet eligendi ipsam enim quisquam, corrupti asperiores nihil excepturi aspernatur placeat iure.",
    // },
    // {
    //   id: "5",
    //   tag: "UI/UX",
    //   title: "Chul urina",
    //   img: work5,
    //   imgSmall: workSmall5,
    //   bg: "#FFFAE9",
    //   client: "Envato",
    //   langages: "Photoshop",
    //   link: "https://www.envato.com",
    //   linkText: "www.envato.com",
    //   description:
    //     "  Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cupiditate non suscipit voluptatibus minima ullam maiores sequi nihil placeat error, vero eaque doloremque reiciendis amet pariatur consequuntur. Nostrum, dolore, amet eligendi ipsam enim quisquam, corrupti asperiores nihil excepturi aspernatur placeat iure.",
    // },
    // {
    //   id: "6",
    //   tag: "Video",
    //   title: "Chul urina",
    //   img: work6,
    //   imgSmall: workSmall6,
    //   bg: "#F4F4FF",
    //   client: "Envato",
    //   langages: "Vimeo",
    //   link: "https://www.envato.com",
    //   linkText: "www.envato.com",
    //   description:
    //     "  Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cupiditate non suscipit voluptatibus minima ullam maiores sequi nihil placeat error, vero eaque doloremque reiciendis amet pariatur consequuntur. Nostrum, dolore, amet eligendi ipsam enim quisquam, corrupti asperiores nihil excepturi aspernatur placeat iure.",
    // },
    // {
    //   id: "7",
    //   tag: "UI/UX",
    //   title: "Chul urina",
    //   img: work7,
    //   imgSmall: workSmall7,
    //   bg: "#FFF0F8",
    //   client: "Envato",
    //   langages: "Photoshop",
    //   link: "https://www.envato.com",
    //   linkText: "www.envato.com",
    //   description:
    //     "  Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cupiditate non suscipit voluptatibus minima ullam maiores sequi nihil placeat error, vero eaque doloremque reiciendis amet pariatur consequuntur. Nostrum, dolore, amet eligendi ipsam enim quisquam, corrupti asperiores nihil excepturi aspernatur placeat iure.",
    // },
    // {
    //   id: "8",
    //   tag: "Web Design",
    //   title: "Chul urina",
    //   img: work8,
    //   imgSmall: workSmall8,
    //   bg: "#FFF0F8",
    //   client: "Envato",
    //   langages: "HTML, CSS, Javascript",
    //   link: "https://www.envato.com",
    //   linkText: "www.envato.com",
    //   description:
    //     "  Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cupiditate non suscipit voluptatibus minima ullam maiores sequi nihil placeat error, vero eaque doloremque reiciendis amet pariatur consequuntur. Nostrum, dolore, amet eligendi ipsam enim quisquam, corrupti asperiores nihil excepturi aspernatur placeat iure.",
    // },
    // {
    //   id: "9",
    //   tag: "Logo",
    //   title: "Chul urina",
    //   img: work9,
    //   imgSmall: workSmall9,
    //   bg: "#FCF4FF",
    //   client: "Feepik",
    //   langages: "Figma",
    //   link: "https://www.freepik.com/free-photos-vectors/market-logo",
    //   linkText: "www.freepik.com",
    //   description:
    //     "  Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cupiditate non suscipit voluptatibus minima ullam maiores sequi nihil placeat error, vero eaque doloremque reiciendis amet pariatur consequuntur. Nostrum, dolore, amet eligendi ipsam enim quisquam, corrupti asperiores nihil excepturi aspernatur placeat iure.",
    // },
  ];
  const [data, setData] = useState(workItems);

  // Elements for Blogs section
  const blogsData = [
    // {
    //   id: "1",
    //   img: blog1,
    //   imgSmall: blogSmall1,
    //   date: "177 April",
    //   category: "Inspiration",
    //   title: "How to Own Your Audience by Creating an Email List.",
    //   bg: "#FCF4FF",
    //   description:
    //     "Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum conseqr quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consetur quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. ",
    // },
    // {
    //   id: "4",
    //   img: blog4,
    //   imgSmall: blogSmall4,
    //   date: "000 April",
    //   category: "Inspiration",
    //   title: "Everything You Need to Know About Web Accessibility.",
    //   bg: "#EEFBFF",
    //   description:
    //     "Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum conseqr quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consetur quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. ",
    // },
    // {
    //   id: "2",
    //   img: blog2,
    //   imgSmall: blogSmall2,
    //   date: "21 April",
    //   category: "Web Design",
    //   title: "The window know to say beside you",
    //   bg: "#FFF0F0",
    //   description:
    //     "Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum conseqr quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consetur quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. ",
    // },
    // {
    //   id: "5",
    //   img: blog5,
    //   imgSmall: blogSmall5,
    //   date: "27 April",
    //   category: "Inspiration",
    //   title: "Top 10 Toolkits for Deep Learning in 2021.",
    //   bg: "#FCF4FF",
    //   description:
    //     "Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum conseqr quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consetur quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. ",
    // },
    // {
    //   id: "3",
    //   img: blog3,
    //   imgSmall: blogSmall3,
    //   date: "27 April",
    //   category: "Inspiration",
    //   title: "How to Own Your Audience by Creating an Email List.",
    //   bg: "#FCF4FF",
    //   description:
    //     "Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum conseqr quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consetur quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. ",
    // },
    // {
    //   id: "6",
    //   img: blog6,
    //   imgSmall: blogSmall6,
    //   date: "27 April",
    //   category: "Inspiration",
    //   title: "Everything You Need to Know About Web Accessibility.",
    //   bg: "#EEFBFF",
    //   description:
    //     "Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum conseqr quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consetur quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. ",
    // },
  ];

  // Menu items for Homepage One
  const menuItem = [
    {
      id: "01",
      name: "Home",
      link: "/",
      icon: <AiOutlineHome />,
    },
    {
      id: "02",
      name: "About",
      link: "/about",
      icon: <FaRegUser />,
    },
    {
      id: "06",
      name: "Resume",
      link: "/resume",
      icon: <CgNotes />,
    },
    // {
    //   id: "03",
    //   name: "Works",
    //   link: "/works",
    //   icon: <FiCodesandbox />,
    // },
    // {
    //   id: "04",
    //   name: "Blogs",
    //   link: "/blogs",
    //   icon: <FaBlogger />,
    // },
    {
      id: "05",
      name: "Contact",
      link: "/contact",
      icon: <RiContactsBookLine />,
    },
  ];

  // Menu items for Homepage Two
  const menuItemTwo = [
    {
      id: "01",
      name: "About",
      link: "/homeTwo/about",
      icon: <FaRegUser />,
    },
    {
      id: "02",
      name: "Resume",
      link: "/homeTwo/resume",
      icon: <CgNotes />,
    },
    {
      id: "03",
      name: "Works",
      link: "/homeTwo/works",
      icon: <FiCodesandbox />,
    },
    {
      id: "04",
      name: "Blogs",
      link: "/homeTwo/blogs",
      icon: <FaBlogger />,
    },
    {
      id: "05",
      name: "Contact",
      link: "/homeTwo/contact",
      icon: <RiContactsBookLine />,
    },
  ];

  // Slider image for Clients
  const sliderImg = [];

  // serviceArray items for about page
  const serviceArray = [
    {
      id: "1",
      icon: icon,
      title: "System Administration",
      des: "Manage linux and windows servers. Configure and maintain servers and network.",
      color: "#D566FF",
      bg: "#FCF4FF",
    },
    {
      id: "2",
      icon: icon1,
      title: "Database Management",
      des: "Configure and maintain various database servers like MySQL and Redis.",
      color: "#DDA10C",
      bg: "#FEFAF0",
    },
    {
      id: "3",
      icon: icon5,
      title: "Web Development",
      des: "Develop and maintain websites using PHP, Laravel, Javascript, React, Vue, etc.",
      color: "#269FFF",
      bg: "#F3FAFF",
    },
  ];

  // educationArray items for Resume page
  const educationArray = [
    {
      id: 1,
      date: "2011-2015",
      title: "Computer and Information Science, General",
      place: "Palo Verde College, Blythe, CA",
      bg: "#FFF4F4",
    },
    {
      id: 1,
      date: "2009-2011",
      title: "General Studies",
      place: "Twin Palms High School, Blythe, CA",
      bg: "#FFF4F4",
    },

  ];
  // experiencesArray items for Resume page
  const experiencesArray = [
    {
      id: 1,
      date: "2020-Present",
      title: "Business Owner",
      place: "EZSCALE Hosting, LLC",
      bg: "#F2F4FF",
      bullets: [
          "Founded and managed hosting services, focusing on server, VPS, and SaaS product automation.",
          "Maintained high service reliability and uptime, enhancing client satisfaction.",
          "Integrated various payment gateways, including Stripe and PayPal.",
          "Customized virtual machine solutions using KVM/QEMU for specific client needs.",
      ],
    },
    {
      id: 2,
      date: "2021-2023",
      title: "Technology Consultant",
      place: "WTW",
      bg: "#EEF5FA",
      bullets: [
        "Spearheaded deployment in Azure and on-prem environments, optimizing software integration.",
        "Developed and implemented PowerShell scripts to automate client workflows.",
        "Employed Python scripting for efficient data analysis and processing in Excel.",
        "Facilitated seamless software installation and troubleshooting for insurance clients.",
      ]
    },
    {
      id: 3,
      date: "2019-2021",
      title: "Cloud Services Engineer",
      place: "ScaleMatrix",
      bg: "#EEF5FA",
      bullets: [
          "Provided technical support to clients utilizing Microsoft RDS and VMWare Horizon for our VDI Desktop offerings, resolving issues promptly.",
          "Developed scalable solutions that supported the growth of our clients' businesses, delivering technical assistance when needed.",
          "Executed live and annual testing for disaster recovery operations, ensuring clients' data integrity and minimizing downtime.",
          "Leveraged VMWare cloud director for Edge Gateway management, client environment provisioning, IPSEC VPNs, and VM creation.",
          "Streamlined workflow by creating PowerShell scripts for monitoring and data gathering, integrating them into a web API for enhanced efficiency.",
          "Provided hands-on remote support for our clients, respond to client queries through our ScalePanel, assisted with racking & un-racking client/company gear, answering phone calls from our clients.",
      ],
    },
    {
      id: 4,
      date: "2018-2019",
      title: "Warehouse Associate",
      place: "Marmol Export, USA",
      bg: "#F2F4FF",
      bullets: [
          "Managed warehousing operations for both internal and external inventory.",
          "Efficiently picked and packed customer orders, ensuring timely delivery.",
          "Revamped and implemented a streamlined workflow for handling inventory, improving overall efficiency.",
          "Leveraged previous experience from Home Depot freight crew to enhance warehouse operations.",
      ],
    },
    {
      id: 5,
      date: "2017-2018",
      title: "Freight Team Associate",
      place: "The Home Depot",
      bg: "#EEF5FA",
      bullets: [],
    },
    {
      id: 6,
      date: "2016-2017",
      title: "Customer Service Associate",
      place: "Kmart",
      bg: "#F2F4FF",
      bullets: [],
    },
  ];
  // awardsArray items for Resume page
  const awardsArray = [
    // {
    //   id: 1,
    //   date: "2015-2017",
    //   title: "  Graphic Designer",
    //   place: "Web Graphy, Los Angeles, CA",
    //   bg: "#FCF4FF",
    // },
    //
    // {
    //   id: 2,
    //   date: "2014 - 2015",
    //   title: "Jr. Web Developer",
    //   place: "Creative Gigs.",
    //   bg: "#FCF9F2",
    // },
    //
    // {
    //   id: 3,
    //   date: "2015-2017",
    //   title: "Best Freelancer",
    //   place: "Fiver & Upwork Level 2 & Top Rated",
    //   bg: "#FCF4FF",
    // },
  ];

  // Working Skills items for Resume page
  const lineArray = [
    {
      id: "01",
      color: "#FF6464",
      name: "PHP/Laravel",
      number: "80",
    },
    {
      id: "02",
      color: "#9272D4",
      name: "React",
      number: "20",
    },
    {
      id: "03",
      color: "#5185D4",
      name: "Vue.js",
      number: "80",
    },
    {
      id: "04",
      color: "#CA56F2",
      name: "JavaScript",
      number: "70",
    },
    {
      id: "05",
      color: "#594adc",
      name: "PowerShell",
      number: "80",
    },
    {
      id: "06",
      color: "#e59d00",
      name: "Rust",
      number: "10",
    },
    {
      id: "07",
      color: "#00b43f",
      name: "Golang",
      number: "10",
    },
  ];

  // Personal information for contact pages
  const contactArray = [
    {
      id: "01",
      icon: iconPhone,
      title: "Phone ",
      item1: "+17604646354",
      bg: "#FCF4FF",
    },
    {
      id: "02",
      icon: iconEmail,
      title: "Email ",
      item1: "andrew@amurray.me",
      bg: "#EEFBFF",
    },
  ];

  return {
    handleTheme,
    check,
    local,
    handleData,
    data,
    singleData,
    handleModelData,
    isOpen,
    setIsOpen,
    blogsData,
    handleBlogsData,
    menuItem,
    NavLink,
    menuItemTwo,
    serviceArray,
    sliderImg,
    educationArray,
    experiencesArray,
    awardsArray,
    lineArray,
    contactArray,
  };
};

export default AllData;

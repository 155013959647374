import React from "react";
import downloadIcon from "../../assets/images/download.png";
import cvFile from "../../assets/andrew_murray_resume.pdf";
import {FaGithub, FaLinkedinIn,} from "react-icons/fa";
import PageTitle from "../../Share/PageTitle";

const HomePage = () => {
  return (
    <>
      <PageTitle title="Home"></PageTitle>
      {/* End pagetitle */}

      <div
        className="flex flex-col items-center h-[100vh] md:h-[90vh] lg:h-[80vh]  xl:h-[71vh]  justify-center"
        data-aos="fade"
      >
        <h3 className="mt-6 mb-1 text-5xl font-semibold dark:text-white">
          Andrew Murray
        </h3>
        <p className="mb-4 text-[#7B7B7B]">Sr Software Engineer &amp; Systems Administrator</p>
        {/* Avatar Info End */}

        {/* Social information start */}
        <div className="flex space-x-3">
          <a
            href="https://amurray.me/linkedin"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="socialbtn text-[#0072b1]">
              <FaLinkedinIn />
            </span>
          </a>

          <a
              href="https://amurray.me/github"
              target="_blank"
              rel="noopener noreferrer"
          >
            <span className="socialbtn text-[#0072b1]">
              <FaGithub />
            </span>
          </a>
        </div>
        {/* Social information start */}

        {/* Download button start */}
        <a
          href={cvFile}
          download
          className="flex items-center bg-gradient-to-r from-[#FA5252] to-[#DD2476] duration-200 transition ease-linear hover:bg-gradient-to-l from-[#DD2476]  to-[#fa5252ef] px-8 py-3 text-lg text-white rounded-[35px] mt-6"
        >
          <img src={downloadIcon} alt="icon" className="mr-2" />
          Download CV
        </a>

        {/* Download button End */}
      </div>
    </>
  );
};

export default HomePage;
